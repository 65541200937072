<template>
  <div class="mobDateFilter">
    <div class="mobDateFilter_date">
      {{ filterMontagesByDateText }}
    </div>
    <div class="mobDateFilter_nav">
      <div class="mobDateFilter_nav_left">
        <v-btn 
          @click="filterDate('before')"
          icon
          color="black"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="mobDateFilter_nav_center">
        <v-btn 
          @click="filterDate('yesterday')"
          :color="yesterdayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'" 
          small
          text
        >
          Вчера
        </v-btn>
        <v-btn
          @click="filterDate('today')"
          :color="todayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'"
          small
          text
        >
          Сегодня
        </v-btn>
        <v-btn
          :color="tomorrowDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'"
          @click="filterDate('tomorrow')"
          small
          text
        >
          Завтра
        </v-btn>
      </div>
      <div class="mobDateFilter_nav_right">
        <v-btn
          @click="filterDate('next')"
          icon
          color="black"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateFilter',
  data: () => ({
    todayDate: new Date().toISOString().substr(0, 10),
    date: new Date(new Date().toISOString().substr(0, 10))
  }),
  computed: {
    filterMontagesByDateText() {
      let date = this.date
      return new Date(date).toLocaleString('ru', {weekday: 'long', day: 'numeric', month: 'short'})
    },
    filterMontagesByDateNumber() {
      let date = this.date
      return date.toISOString().substr(0, 10)
    },
    yesterdayDate() {
      let date = new Date()
      let goodDate = new Date(new Date().setDate(date.getDate()-1))
      return goodDate.toISOString().substr(0, 10)
    },
    tomorrowDate() {
      let date = new Date()
      let goodDate = new Date(new Date().setDate(date.getDate()+1))
      return goodDate.toISOString().substr(0, 10)
    }
  },
  methods: {
    filterDate(val) {
      let date = this.date
      let newDate = ''
      switch (val) {
        case 'tomorrow':
          this.date = new Date(this.tomorrowDate)
          break;
        case 'yesterday':
          this.date = new Date(this.yesterdayDate)
          break;
        case 'today':
          this.date = new Date(this.todayDate)
          break;
        case 'before':
          newDate = new Date(date.setDate(date.getDate()-1))
          this.date = newDate
          break;
        case 'next':
          newDate = new Date(date.setDate(date.getDate()+1))
          this.date = newDate
          break;
      }
      this.$emit('changeDate', this.date)
    }
  }
}
</script>

<style lang="scss">
.mobDateFilter {
  background-color: white;

  &_date {
    font-size: 0.8em;
    padding: 0.4em 5px;
    padding-bottom: 0em;
    text-align: center;
  }

  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4em 5px;
    padding-top: 0em;
  }
}
</style>