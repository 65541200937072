<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <NewComment :item="item" :item_id="item_id" @load="loadComments" />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-center py-0"
      v-for="comment in filtredComments"
      :key="comment.id"
    >
      <v-row>
        <v-col class="py-0">
          <v-row>
            <v-col cols="2">
              <my-mini-avatar :user="comment.fromData" />
            </v-col>
            <v-col cols="10">
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-subheader class="pa-0" style="height: 23px;">
                    <span style="font-weight: 500;">{{ comment.fromData.displayName }}</span>
										<span v-if="comment.program" class="ml-2">Авто. создан</span>
                    <span class="ml-2">{{ $formatDateTime(new Date(comment.date)) }}</span>
                  </v-subheader>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-row>
                    <v-col 
											v-if="comment.text"
											class="py-0"
											v-html="comment.text"
										/>
                    <v-col 
                      v-if="!comment.text && (!comment.files || comment.files.length === 0)"
                      class="py-0"
                    >
                      Пустой комментарий
                    </v-col>
                    <v-col v-if="comment.files && comment.files.length > 0" cols="12" class="py-0">
                      <v-container fluid class="pa-0">
                        <v-row>
                          <v-col
                            v-for="(image, index_image) in comment.files"
                            :key="index_image"
                            class="d-flex child-flex"
                            cols="4"
                          >
                            <my-preview-image :canDownload="true" :fbPath="image" @delete="deleteImage(image, comment)" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NewComment from './NewComment.vue'
import { manufactoryCollection } from '@/plugins/firebase'
import api from '@/utils/api'
const snapshots = {
  comments: null
}

export default {
  components: {
    NewComment
  },
  props: ['item_id', 'item', 'showProgramComment'],
  data: () => ({
    comments: [],
  }),
  created() {
    this.loadComments()
  },
  // watch: {
  //   item_id(oldVal, newVal) { 
  //     this.loadComments()
  //   },
  // },
  methods: {
    loadData() {
      this.loadComments()
    },
    async deleteImage(image, comment) {
      comment.files = comment.files.map(function(file) {
        return file !== image ? file : "deleted";
      });
      await api.put('comments/update',{ id: comment.id, files: comment.files.join(',') }).then(response => {
        if(response.status == 200 && response.data.status){
          this.$emit('delete-image')
          setTimeout(() => this.loadComments(), 1000)
        }
      })
		},
    async loadComments() {
      // if (snapshots.comments) {
      //   snapshots.comments()
      //   snapshots.comments = null
      // }

      if (this.item_id) {
        // let queryStore = manufactoryCollection.doc(this.item_id).collection('comments')
        // snapshots.comments = queryStore.onSnapshot(async snapshot => {
        //   let comments = []
        //   for (let doc of snapshot.docs) {
        //     let comment = { ...doc.data(), id: doc.id }
        //     let commentFrom = await comment.from.get()
        //     comment.from = commentFrom.data()
        //     comments.push(comment)
        //   }
        //   comments = comments.sort(function(a,b){
        //     return new Date(b.date) - new Date(a.date);
        //   })

        //   this.comments = comments
        // })
        let data = await api.get('montages/get', this.item_id).then(response =>{
            if(response.status == 200){
              console.log(response.data.data, 'loadComments')
              return response.data.data || []
              
            }
          })

          

          data.comments.forEach(el => {
            if(el.files){
              el.files = el.files.split(',')
            }
          })
          
          this.comments = data.comments || []
          // this.$store.commit('comments/SET_COMMENTS', data.comments)
      }
    },
  },
	computed: {
		filtredComments() {
			let comments = this.comments
      // let comments = this.$store.getters['comments/comments']
			if (!this.showProgramComment) {
				comments = comments.filter(x => !x.program)
			}

			return comments
		}
	},
  beforeDestroy() {
    // if (snapshots.comments) {
    //   snapshots.comments()
    //   snapshots.comments = null
    // }
  }
}
</script>